<template>
  <tr>
    <td class="justify-left">
      <v-btn
        :disabled="!props.item.business_id"
        outline
        small
        link
        :to="{
          name: 'contract',
          params: {
            id: props.item.business_id
          }
        }"
      >
        Contrato
      </v-btn>
    </td>
    <td class="justify-left">
      <span v-if="props.item.host.mac_address || props.item.host.serial">
        <strong v-if="props.item.host.mac_address">
          {{ props.item.host.mac_address }}
        </strong>
        <br v-if="props.item.host.mac_address && props.item.host.serial">
        <strong v-if="props.item.host.serial">
          {{ props.item.host.serial }}
        </strong>
      </span>
      <span v-else>
        <strong>
          {{ props.item.host.identification }}
        </strong>
      </span>
    </td>
    <td class="justify-left">
      <span v-if="props.item.device && props.item.device.network_names">
        {{ props.item.device.network_names }}
      </span>
      <br>
      <strong>
        {{ props.item.device.nome_disp }}
      </strong>
    </td>
    <td class="justify-left">
      <span v-if="props.item.device_child">
        <span v-if="props.item.device_child && props.item.device_child.parent" class="primary--text">
          <span>
            {{ props.item.device_child.parent.type.name }}
          </span>  
          <span>
            {{ props.item.device_child.parent.name }}
          </span>
          <br>
        </span> 
        <strong v-if="props.item.device_child">
          {{ props.item.device_child.type.name }}
        </strong> 
        <strong v-if="props.item.device_child">
          {{ props.item.device_child.name }}
        </strong>
      </span>
      <span v-else>
        - - -
      </span>
    </td>
    <td class="justify-left">
      <span v-if="props.item.contract && props.item.contract.address">
        {{ props.item.contract.address.address_full }}
      </span>
      <span v-else>
        - - -
      </span>
    </td>
    <td class="justify-left">
      <span v-if="props.item.contract && props.item.contract.person">
        {{ props.item.contract.person.name }}
      </span>
      <span v-else>
        - - -
      </span>
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'TicketHosts',
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style scoped>
td {
  padding: 0 5px !important;
}
th {
  padding: 0 5px !important;
}
</style>